import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import {  DataService, MixpanelService } from '../services/index'
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import countryData from './country.json';
import { Base64 } from 'js-base64';
import * as moment from 'moment';

import { TimeFormaterPipe } from '../pipes/time-formater.pipe';
import { PixelService } from '../pixel.service';
import moengage from "@moengage/web-sdk";
import { NameSplitPipe } from '../name-split.pipe';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  countries: any = countryData;
  paytotalfare:any;
  currencyfare:any;
  private sub: any;
  bookingdetails:any;
  returndetails:any;
  bookingdata:any={};
  bookingdataReturn:any={};
  passengers:any=[];
  fsname:any;
  lsname:any;
  isdno:any;
  phoneno:any;
  country:any;
  residence:any;
  insurance_dob:any;
  returnpassengers:any=[];
  formValidation=[];
  isError:boolean=false;
  errorMessage:any;
  res:any;
  insuaranceRes:any;
  is_return:false;
  bus_operator:any;
  loading=false;
  paymentForm: UntypedFormGroup;
  submitted = false;
  formDataObj={}
  accepted_terms=false;
  isInsuarance=false;
  insuaranceProvider:any;
  policyAmount=0;
  today = new Date();
  defaultDate=new Date();
  insuaranceData:any;
  selectedInsurance: string = "noInsurance";
  private nameSplitPipe = new NameSplitPipe();
  validResults: string[] = ['mashpoa', 'climax'];
  cap:any;
  counter:any;
  hideFeedbackBtn=false;
  bus_arrival_date:any;
  constructor(
    private router: Router,
    private dataService:DataService,
    private _fb: UntypedFormBuilder,
    private mixpanelService: MixpanelService,
  ) {
    this.defaultDate.setFullYear(this.defaultDate.getFullYear() - 10);

   }

  ngOnInit() {
    window.scroll(0,0);
    this.dataService.returnPaymentValueChange.subscribe(message =>{
      if(!message){
        console.log('no message')
        message = localStorage.getItem('payments-buupass') ?? ''
        if(!message) {
         this.router.navigate(['/'])
       }
      }else{
        console.log('message')
        localStorage.setItem('payments-buupass', message)
      }

      let encoded =JSON.parse(Base64.decode(message))
      this.bookingdetails = JSON.parse(Base64.decode(encoded.travel));
      this.insuaranceData = this.mergeInsuranceData(this.bookingdetails.insurance);
      this.validResults = this.bookingdetails.insurance_operators
      this.cap = this.bookingdetails.insurance_cap
      this.counter = this.bookingdetails.insurance_counter
      this.bus_arrival_date = this.getArrivalDate(this.bookingdetails.search_details.traveldate, this.bookingdetails.result.departure_time, this.bookingdetails.result.arrival_time);
     
      console.log(this.bookingdetails)


      if(encoded.returning){
        this.returndetails = JSON.parse(Base64.decode(encoded.return));
        this.paytotalfare=this.bookingdetails.totalfare + this.returndetails.totalfare ;
        this.returndetails.result.operator.booked_seat_session_id=this.bookingdetails.booking_session_id
      }else{
        this.paytotalfare=this.bookingdetails.totalfare;
        this.bookingdetails.result.operator.booked_seat_session_id=this.bookingdetails.booking_session_id
      }


      this.currencyfare = this.bookingdetails.fare_currency;

      this.bus_operator=this.bookingdetails.result.operator

      this.is_return=encoded.returning;
      this.bookingdetails.seatsSelected.forEach((seat) => {
        let fsname='fname'+seat.id;
        let lsname='lname'+seat.id;
        let isdno='idno'+seat.id;
        let phoneno = 'phoneno'+ seat.id;
        let country = 'country'+ seat.id;
        let residence = 'residence'+ seat.id;
        let insurance_dob = 'insurance_dob' + seat.id
        this.formDataObj[fsname]=['', [Validators.required,Validators.pattern("^[a-zA-Z ']+$")]];
        this.formDataObj[lsname]=['', [Validators.required,Validators.pattern("^[a-zA-Z ']+$")]];
        this.formDataObj[isdno]=['', [Validators.required, Validators.minLength(7)]];
        this.formDataObj[phoneno]=['', [Validators.required, Validators.minLength(10),Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]];
        this.formDataObj[country]=['', Validators.required];
        this.formDataObj[insurance_dob]=['']
        // this.formDataObj[residence]=['', [Validators.required,Validators.pattern("^[a-zA-Z ']+$")]];
      });
    });
    
    this.formDataObj['mobileNumber']=['',  [Validators.required, Validators.minLength(10),Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]];
    this.formDataObj['fullname']=['', Validators.required];
    this.formDataObj['email']=[''];

    
    
    this.paymentForm =this._fb.group(this.formDataObj);

    this.mixpanelService.track('PageView', {
      pageName: 'Passenger Details',
      source: 'buupassSite',
      role: 'customer',
    });


   

  }

  



  get f() { return this.paymentForm.controls; }

  acceptedTerms(){
      this.accepted_terms=!this.accepted_terms;
  }
  handleClick(event: Event) {

  }
  dateOfBirthValidator(control: AbstractControl): { [key: string]: any } | null {
    const valid = /^(0[1-9]|[12][0-9]|3[01])[-\/](0[1-9]|1[0-2])[-\/]\d{4}$/.test(control.value);
    return valid ? null : { invalidDate: true };
  }

 getArrivalDate(departureDate: string, departureTime: string, arrivalTime: string): string {
    // Convert times to Date objects for comparison
    const departureDateTime = new Date(`${departureDate}T${departureTime}`);
    const arrivalDateTime = new Date(`${departureDate}T${arrivalTime}`);

    // If the arrival time is earlier than the departure time, it means arrival is on the next day
    if (arrivalDateTime.getTime() <= departureDateTime.getTime()) {
        arrivalDateTime.setDate(arrivalDateTime.getDate() + 1);
    }

    // Format the arrival date as YYYY-MM-DD
    return arrivalDateTime.toISOString().split("T")[0];
}


  isInsuaranceClicked(event: any, price: number,insuarance:any) {
    this.insuaranceProvider = insuarance;
    const seatsSelected = this.bookingdetails.seatsSelected.length;
    if (price > 0) {
      this.isInsuarance = true;
     
        this.policyAmount = price * seatsSelected;
        this.paytotalfare = this.bookingdetails.totalfare + this.policyAmount;
     
    } else {
      this.isInsuarance = false;
      this.policyAmount = 0;
      this.paytotalfare = this.bookingdetails.totalfare;
    }
  }


//   submitForm(){
//     this.submitted=true;
//     if(!this.accepted_terms){
//       return
//     }

//     if (this.paymentForm.valid) {
//     let value = this.paymentForm.value;
//     this.isError=false;
//     this.loading=true;
//     this.passengers=[];
//     this.bookingdetails.seatsSelected.forEach((seat) => {
//       let passenger:any;
//       this.fsname='fname'+seat.id;
//       this.lsname='lname'+seat.id;
//       this.isdno='idno'+seat.id;
//       this.phoneno = 'phoneno'+seat.id;
//       this.country = 'country'+seat.id;
//       this.residence = 'residence'+seat.id;
//       this.insurance_dob = 'insurance_dob'+seat.id;
      

//   let seatprice=(parseInt(this.bookingdetails.totalfare)/this.bookingdetails.seatsSelected.length)
//   let currentFare=0
//   if(this.bookingdetails.result.is_shuttle){
//     currentFare = seatprice
//   }else{
//     currentFare =Number(seat.fare)
//   }

//    passenger={
//       "full_name": value[this.fsname] + ' ' + value[this.lsname],
//       "id_or_passport": value[this.isdno].toString(),
//       "seat": {
//         "id": seat.id,
//         "fare": currentFare,
//         "class": seat.space_type
//       },
//       "phone_number":  "0"+value[this.phoneno].substr(value[this.phoneno].length - 9),
//       "residence":  this.bookingdetails.result.from,
//   }

//   if(this.isInsuarance){

//     var pdob = formatDate(value[this.insurance_dob],"dd-MM-yyyy","en-KE");

//     passenger.dob=pdob;
//   }

//   this.passengers.push(passenger);
// });

// if(this.is_return){
//   this.returnpassengers=[];
//   this.returndetails.seatsSelected.forEach((seat) => {
//     let passenger={};
//     let seatprice=(parseInt(this.returndetails.totalfare)/this.returndetails.seatsSelected.length)
//     let currentFare=0
//     if(this.returndetails.result.is_shuttle){
//       currentFare = seatprice
//     }else{
//       currentFare =Number(seat.fare)
//     }
//     passenger={
//       "full_name": value[this.fsname] + ' ' + value[this.lsname],
//       "id_or_passport": value[this.isdno].toString(),
//       "seat": {
//         "id": seat.id,
//         "fare": currentFare,
//         "class": seat.space_type
//       },
//       "phone_number":  "0"+value[this.phoneno].substr(value[this.phoneno].length - 9),
//       "residence":  this.bookingdetails.result.from
//   }
// this.returnpassengers.push(passenger);
// });
//   this.bookingdataReturn ={
//       "booking_channel": "web",
//       "transaction_reference": "",
//       "referral_code": "",
//       "residence":"Nairobi",
//       "promo_code": "",
//       "selected_bus": {
//         "from": this.returndetails.result.from,
//         "to": this.returndetails.result.to,
//         "departure_date": this.returndetails.result.departure_date,
//         "bus_type_id": this.returndetails.result.bus_type_id,
//         "operator": this.returndetails.result.operator,
//         "is_express_travel": this.returndetails.result.is_express_travel,
//         "number_of_booked_seats": this.returndetails.result.number_of_seats_booked,
//         "id": this.returndetails.result.id,
//         "make": this.returndetails.result.make,
//         "model": this.returndetails.result.model,
//         "route_id": this.returndetails.result.route_id,
//         "route_schedule_id":  this.returndetails.result.route_schedule_id,
//         "route_schedule_code":(this.returndetails.result.is_shuttle) ? '0' :  this.returndetails.result.route_schedule_code,
//         "departure_time": (this.returndetails.result.is_shuttle)
//         ? (this.returndetails.result.operator.alias == 'climax')
//         ? this.returndetails.result.departure_time
//         : '00:00:00'
//         :  this.returndetails.result.departure_time,
//         "departure_timestamp": this.returndetails.result.departure_timestamp,
//         "arrival_time":(this.returndetails.result.is_shuttle)
//         ? (this.returndetails.result.operator.alias == 'climax')
//         ? this.returndetails.result.arrival_time
//         : '00:00:00'
//         :  this.returndetails.result.arrival_time,
//         "number_of_available_seats": this.returndetails.result.number_of_available_seats,
//         "fare":''+this.returndetails.totalfare + '.00',
//         "boarding_points": this.returndetails.result.boarding_points,
//         "dropoff_points":this.returndetails.result.dropoff_points,
//         "seats":this.returndetails.result.seats,
//         "seat_types": this.returndetails.result.seats_types,
//         "number_of_window_seats": this.returndetails.result.number_of_window_seats,
//         "last_booking": this.returndetails.result.last_booking,
//         "amenities":this.returndetails.result.amenities,
//         "origin_city_id": this.returndetails.result.origin_city_id,
//         "destination_city_id": this.returndetails.result.destination_city_id,
//         "bus_capacity": this.returndetails.result.bus_capacity,
//         "trip_id":this.returndetails.result.trip_id,
//         "boarding_point": this.returndetails.pickup_location,
//         "drop_off_point": this.returndetails.dropoff_location,
//       },
//       "payee": {
//         "phone_number":value.mobileNumber,
//   		    "name": value.fullname,
//   		     "email": value.email
//       },

//       "boarding_point": this.returndetails.pickup_location,
//       "drop_off_point": this.returndetails.dropoff_location,
//       "date_of_travel": this.returndetails.result.departure_date,
//       "departure_time": this.returndetails.result.departure_time,
//       "arrival_time": this.returndetails.result.arrival_time,
//       "route_schedule_id": this.returndetails.result.route_schedule_id,
//       "operator": this.returndetails.result.operator,
//       "origin_city_name":this.returndetails.result.from,
//       "destination_city_name": this.returndetails.result.to,
//       "origin_city_id":this.returndetails.result.origin_city_id,
//       "destination_city_id": this.returndetails.result.destination_city_id,
//       "passengers":  this.returnpassengers,
//       "seatsSelected":this.returndetails.seatsSelected,
//       "total_fare": this.returndetails.total_fare,
//       "children": []
//     }

//     this.bookingdata ={
//       "booking_channel": "web",
//       "transaction_reference": "",
//       "referral_code": "",
//       "residence":"Nairobi",
//       "promo_code": "",
//       "selected_bus": {
//         "from": this.bookingdetails.result.from,
//         "to": this.bookingdetails.result.to,
//         "departure_date": this.bookingdetails.result.departure_date,
//         "bus_type_id": this.bookingdetails.result.bus_type_id,
//         "operator": this.bookingdetails.result.operator,
//         "is_express_travel": this.bookingdetails.result.is_express_travel,
//         "number_of_booked_seats": this.bookingdetails.result.number_of_seats_booked,
//         "id": this.bookingdetails.result.id,
//         "make": this.bookingdetails.result.make,
//         "model": this.bookingdetails.result.model,
//         "route_id": this.bookingdetails.result.route_id,
//         "route_schedule_id":  this.bookingdetails.result.route_schedule_id,
//         "route_schedule_code":(this.bookingdetails.result.is_shuttle) ? '0' :  this.bookingdetails.result.route_schedule_code,
//         "departure_time": (this.bookingdetails.result.is_shuttle)
//         ? (this.bookingdetails.result.operator.alias == 'climax')
//         ? this.bookingdetails.result.departure_time
//         :'00:00:00'
//         :  this.bookingdetails.result.departure_time,
//         "departure_timestamp": this.bookingdetails.result.departure_timestamp,
//         "arrival_time":(this.bookingdetails.result.is_shuttle)
//         ? (this.bookingdetails.result.operator.alias == 'climax')
//         ? this.bookingdetails.result.arrival_time
//         : '00:00:00'
//         :  this.bookingdetails.result.arrival_time,
//         "number_of_available_seats": this.bookingdetails.result.number_of_available_seats,
//         "fare": ''+this.bookingdetails.totalfare + '.00',
//         "boarding_points": this.bookingdetails.result.boarding_points,
//         "dropoff_points":this.bookingdetails.result.dropoff_points,
//         "seats":this.bookingdetails.result.seats,
//         "seat_types": this.bookingdetails.result.seats_types,
//         "number_of_window_seats": this.bookingdetails.result.number_of_window_seats,
//         "last_booking": this.bookingdetails.result.last_booking,
//         "amenities":this.bookingdetails.result.amenities,
//         "origin_city_id":this.bookingdetails.result.origin_city_id,
//         "destination_city_id": this.bookingdetails.result.destination_city_id,
//         "bus_capacity": this.bookingdetails.result.bus_capacity,
//         "trip_id":this.bookingdetails.result.trip_id,
//         "boarding_point": this.bookingdetails.pickup_location,
//         "drop_off_point": this.bookingdetails.dropoff_location,
//       },
//       "payee": {
//         "phone_number":value.mobileNumber,
//   		    "name": value.fullname,
//   		     "email": value.email
//       },
//       "boarding_point": this.bookingdetails.pickup_location,
//       "drop_off_point": this.bookingdetails.dropoff_location,
//       "date_of_travel": this.bookingdetails.result.departure_date,
//       "departure_time": this.bookingdetails.result.departure_time,
//       "arrival_time": this.bookingdetails.result.arrival_time,
//       "route_schedule_id":'' + this.bookingdetails.result.route_schedule_id,
//       "operator": this.bookingdetails.result.operator,
//       "origin_city_name":this.bookingdetails.result.from,
//       "destination_city_name": this.bookingdetails.result.to,
//       "origin_city_id":this.bookingdetails.result.origin_city_id,
//       "destination_city_id": this.bookingdetails.result.destination_city_id,
//       "passengers":  this.passengers,
//       "children": []
//     }

//     let bookingdata=Base64.encode(JSON.stringify(this.bookingdata));
//     let bookingdataReturn=Base64.encode(JSON.stringify(this.bookingdataReturn));
//     let bookingdetails=Base64.encode(JSON.stringify(this.bookingdetails));
//     let webformdata={
//       'bookingdata' : bookingdata,
//       'bookingdetails' : bookingdetails,
//       'is_return':this.is_return,
//       'returndata':  bookingdataReturn
//     }

//     let pay=Base64.encode(JSON.stringify(webformdata));
//     let paymessage=Base64.encode("Sorry!");
//     this.dataService.changeReviewPaymentMessage(pay);
//     this.router.navigate(['/review-and-pay', paymessage])

// }
//   this.bookingdata ={
//     "booking_channel": "web",
//     "transaction_reference": "",
//     "referral_code": "",
//     "residence":"Nairobi",
//     "promo_code": "",
//     "selected_bus": {
//       "from": this.bookingdetails.result.from,
//       "to": this.bookingdetails.result.to,
//       "departure_date": this.bookingdetails.result.departure_date,
//       "bus_type_id": this.bookingdetails.result.bus_type_id,
//       "operator": this.bookingdetails.result.operator,
//       "is_express_travel": ''+this.bookingdetails.result.is_express_travel,
//       "number_of_booked_seats": this.bookingdetails.result.number_of_seats_booked,
//       "id": ''+this.bookingdetails.result.id,
//       "make": this.bookingdetails.result.make,
//       "model": this.bookingdetails.result.model,
//       "route_id": this.bookingdetails.result.route_id,
//       "route_schedule_id": this.bookingdetails.result.route_schedule_id ,
//       "route_schedule_code": (this.bookingdetails.result.is_shuttle) ? '0' : this.bookingdetails.result.route_schedule_code,
//       "departure_time": (this.bookingdetails.result.is_shuttle)
//       ? (this.bookingdetails.result.operator.alias == 'climax')
//       ? this.bookingdetails.result.departure_time
//       :'00:00:00'
//       :  this.bookingdetails.result.departure_time,
//       "departure_timestamp": this.bookingdetails.result.departure_timestamp,
//       "arrival_time":(this.bookingdetails.result.is_shuttle)
//       ? (this.bookingdetails.result.operator.alias == 'climax')
//       ? this.bookingdetails.result.arrival_time
//       : '00:00:00'
//       :  this.bookingdetails.result.arrival_time,
//       "number_of_available_seats": this.bookingdetails.result.number_of_available_seats,
//       "fare": this.bookingdetails.totalfare ,
//       "boarding_points": this.bookingdetails.result.boarding_points,
//       "dropoff_points":this.bookingdetails.result.dropoff_points,
//       "seats":this.bookingdetails.result.seats,
//       "seat_types": this.bookingdetails.result.seats_types,
//       "number_of_window_seats": this.bookingdetails.result.number_of_window_seats,
//       "last_booking": this.bookingdetails.result.last_booking,
//       "amenities":this.bookingdetails.result.amenities,
//       "origin_city_id":this.bookingdetails.result.origin_city_id,
//       "destination_city_id": this.bookingdetails.result.destination_city_id,
//       "bus_capacity": this.bookingdetails.result.bus_capacity,
//       "trip_id":this.bookingdetails.result.trip_id,
//       "boarding_point": this.bookingdetails.pickup_location,
//       "drop_off_point": this.bookingdetails.dropoff_location,
//     },
//     "payee": {
//       "phone_number":value.mobileNumber,
// 		    "name": value.fullname,
// 		     "email": value.email
//     },
//     "boarding_point": this.bookingdetails.pickup_location,
//     "drop_off_point": this.bookingdetails.dropoff_location,
//     "date_of_travel": this.bookingdetails.result.departure_date,
//     "departure_time": (this.bookingdetails.result.is_shuttle) ? '00:00:00' : this.bookingdetails.result.departure_time,
//     "arrival_time": (this.bookingdetails.result.is_shuttle) ? '00:00:00' : this.bookingdetails.result.arrival_time,
//     "route_schedule_id": this.bookingdetails.result.route_schedule_id,
//     "operator": this.bookingdetails.result.operator,
//     "origin_city_name":this.bookingdetails.result.from,
//     "destination_city_name": this.bookingdetails.result.to,
//     "origin_city_id":this.bookingdetails.result.origin_city_id,
//     "destination_city_id": this.bookingdetails.result.destination_city_id,
//     "passengers":  this.passengers,
//     "children": []
//   }

// if(this.isInsuarance){


//   let insurance = {
//     "provider": this.insuaranceProvider.key,
//     "id": this.insuaranceProvider.id, // From schedules - insurance - id
//     "dob": "",
//     "nok_name": "",
//     "nok_id_passport": "",
//     "nok_phone": "",
//     "gender": ""
// }
//   this.bookingdata.insurance =insurance 
// }



//   let bookingdata=Base64.encode(JSON.stringify(this.bookingdata));
//   let bookingdataReturn=Base64.encode(JSON.stringify(this.bookingdataReturn));
//   let bookingdetails=Base64.encode(JSON.stringify(this.bookingdetails));

//   let webformdata={
//     'bookingdata' : bookingdata,
//     'bookingdetails' : bookingdetails,
//     'paytotalfare':this.paytotalfare,
//     "policyAmount":this.policyAmount,
//     'is_return':this.is_return,
//     'returndata':  bookingdataReturn
//   }

//   const mixPanelData = {
//     name:value.fullname,
//     email:value.email,
//     residence:this.bookingdetails.result.from,
//     route:this.bookingdetails.result.from + this.bookingdetails.result.to,
//     travelDate: this.bookingdetails.result.departure_date,
//     Busoperator: this.bookingdetails.result.operator,
//     amount: this.bookingdetails.total_fare,
//     ticketCount:this.passengers.length,
//     isReturnUser:this.is_return,
//     isPromoCodeAdded:"",
//     platform: 'web',
//     source: 'buupassSite',
//     role: 'customer',
//   }

//   moengage.track_event('Personal details input', {
//     'PassengerName': value.fullname,
//     'PassengerEmail': value.email,
//     'PassengerPhone': '254' + value.mobileNumber.slice(-9),
//     'PassengerResidence': this.bookingdetails.result.from,
//     'Busoperator': this.bookingdetails.result.operator.alias,
//     'TotalFare':parseInt(this.bookingdetails.total_fare),
//     'TotalPassengers':this.passengers.length
//     // Add more relevant details if needed
//   });

//   let firstName = this.nameSplitPipe.transform(value.fullname, 'first');
//   let lastName = this.nameSplitPipe.transform(value.fullname, 'last');
//   moengage.add_first_name(firstName);
//   moengage.add_last_name(lastName);
//   moengage.add_email(value.email);
//   moengage.add_mobile('254' + value.mobileNumber.slice(-9));
//   	// string
//   moengage.add_user_attribute("Residence", this.bookingdetails.result.from);




//   this.mixpanelService.track('StartPayment', mixPanelData);



//   let pay=Base64.encode(JSON.stringify(webformdata));
//   let paymessage=Base64.encode("Sorry!");
//   this.dataService.changeReviewPaymentMessage(pay);
//   this.router.navigate(['/review-and-pay', paymessage])


//   }




//   }

submitForm() {
  this.submitted = true;
  if (!this.accepted_terms) return;

  if (!this.paymentForm.valid) return;

  this.isError = false;
  this.loading = true;
  this.passengers = this.preparePassengers(this.bookingdetails, this.paymentForm.value);
  
  if (this.is_return) {
    this.returnpassengers = this.preparePassengers(this.returndetails, this.paymentForm.value);
    this.bookingdataReturn = this.prepareBookingData(this.returndetails, this.returnpassengers, this.paymentForm.value);
  }

  this.bookingdata = this.prepareBookingData(this.bookingdetails, this.passengers, this.paymentForm.value);

  let encodedBookingData = Base64.encode(JSON.stringify(this.bookingdata));
  let encodedBookingDataReturn = Base64.encode(JSON.stringify(this.bookingdataReturn));
  let encodedBookingDetails = Base64.encode(JSON.stringify(this.bookingdetails));

  let webformdata = {
    bookingdata: encodedBookingData,
    bookingdetails: encodedBookingDetails,
    paytotalfare: this.paytotalfare,  
    policyAmount: this.policyAmount,  
    is_return: this.is_return,
    returndata: encodedBookingDataReturn

  };

  

  let pay = Base64.encode(JSON.stringify(webformdata));
  let paymessage = Base64.encode("Sorry!");
  this.dataService.changeReviewPaymentMessage(pay);
  this.router.navigate(['/review-and-pay', paymessage]);

  this.trackUserActivity(this.bookingdetails, this.paymentForm.value);
}

/**
 * Prepare passenger list for booking details
 */
private preparePassengers(bookingdetails, formValues): any[] {
  return bookingdetails.seatsSelected.map((seat) => {
    const seatPrice = parseInt(bookingdetails.totalfare) / bookingdetails.seatsSelected.length;
    const currentFare = bookingdetails.result.is_shuttle ? seatPrice : Number(seat.fare);

    let passenger = {
      full_name: `${formValues[`fname${seat.id}`]} ${formValues[`lname${seat.id}`]}`,
      id_or_passport: formValues[`idno${seat.id}`]?.toString() || '',
      seat: {
        id: seat.id,
        fare: currentFare,
        class: seat.space_type
      },
      phone_number: `0${formValues[`phoneno${seat.id}`].substr(-9)}`,
      residence: bookingdetails.result.from,
      dob: ''
    };

    if (this.isInsuarance) {
      passenger.dob = formatDate(formValues[`insurance_dob${seat.id}`], "dd-MM-yyyy", "en-KE");
    }

    return passenger;
  });
}

/**
 * Prepare booking data object
 */
private prepareBookingData(bookingdetails, passengers, formValues) {
  return {
    booking_channel: "web",
    transaction_reference: "",
    referral_code: "",
    residence: "Nairobi",
    promo_code: "",
    selected_bus: {
      from: bookingdetails.result.from,
      to: bookingdetails.result.to,
      departure_date: bookingdetails.result.departure_date,
      bus_type_id: bookingdetails.result.bus_type_id,
      operator: bookingdetails.result.operator,
      is_express_travel: bookingdetails.result.is_express_travel,
      number_of_booked_seats: bookingdetails.result.number_of_seats_booked,
      id: bookingdetails.result.id,
      make: bookingdetails.result.make,
      model: bookingdetails.result.model,
      route_id: bookingdetails.result.route_id,
      route_schedule_id: bookingdetails.result.route_schedule_id,
      route_schedule_code: bookingdetails.result.is_shuttle ? '0' : bookingdetails.result.route_schedule_code,
      departure_time: bookingdetails.result.is_shuttle ? (bookingdetails.result.operator.alias === 'climax' ? bookingdetails.result.departure_time : '00:00:00') : bookingdetails.result.departure_time,
      departure_timestamp: bookingdetails.result.departure_timestamp,
      arrival_time: bookingdetails.result.is_shuttle ? (bookingdetails.result.operator.alias === 'climax' ? bookingdetails.result.arrival_time : '00:00:00') : bookingdetails.result.arrival_time,
      number_of_available_seats: bookingdetails.result.number_of_available_seats,
      fare: `${bookingdetails.totalfare}.00`,
      boarding_points: bookingdetails.result.boarding_points,
      dropoff_points: bookingdetails.result.dropoff_points,
      seats: bookingdetails.result.seats,
      seat_types: bookingdetails.result.seats_types,
      number_of_window_seats: bookingdetails.result.number_of_window_seats,
      last_booking: bookingdetails.result.last_booking,
      amenities: bookingdetails.result.amenities,
      origin_city_id: bookingdetails.result.origin_city_id,
      destination_city_id: bookingdetails.result.destination_city_id,
      bus_capacity: bookingdetails.result.bus_capacity,
      trip_id: bookingdetails.result.trip_id,
      boarding_point: bookingdetails.pickup_location,
      drop_off_point: bookingdetails.dropoff_location,
    },
    payee: {
      phone_number: formValues.mobileNumber,
      name: formValues.fullname,
      email: formValues.email
    },
    boarding_point: bookingdetails.pickup_location,
    drop_off_point: bookingdetails.dropoff_location,
    date_of_travel: bookingdetails.result.departure_date,
    departure_time: bookingdetails.result.departure_time,
    arrival_time: bookingdetails.result.arrival_time,
    route_schedule_id: bookingdetails.result.route_schedule_id,
    operator: bookingdetails.result.operator,
    origin_city_name: bookingdetails.result.from,
    destination_city_name: bookingdetails.result.to,
    origin_city_id: bookingdetails.result.origin_city_id,
    destination_city_id: bookingdetails.result.destination_city_id,
    passengers: passengers,
    children: []
  };
}

/**
 * Track user activity for analytics
 */
private trackUserActivity(bookingdetails, formValues) {
  let mixPanelData = {
    name: formValues.fullname,
    email: formValues.email,
    residence: bookingdetails.result.from,
    route: `${bookingdetails.result.from} to ${bookingdetails.result.to}`,
    travelDate: bookingdetails.result.departure_date,
    Busoperator: bookingdetails.result.operator,
    amount: bookingdetails.total_fare,
    ticketCount: this.passengers.length,
    isReturnUser: this.is_return,
    isPromoCodeAdded: "",
    platform: 'web',
    source: 'buupassSite',
    role: 'customer',
  };

  moengage.track_event('Personal details input', {
    PassengerName: formValues.fullname,
    PassengerEmail: formValues.email,
    PassengerPhone: `254${formValues.mobileNumber.slice(-9)}`,
    PassengerResidence: bookingdetails.result.from,
    Busoperator: bookingdetails.result.operator.alias,
    TotalFare: parseInt(bookingdetails.total_fare),
    TotalPassengers: this.passengers.length
  });

  let firstName = this.nameSplitPipe.transform(formValues.fullname, 'first');
  let lastName = this.nameSplitPipe.transform(formValues.fullname, 'last');
  moengage.add_first_name(firstName);
  moengage.add_last_name(lastName);
  moengage.add_email(formValues.email);
  moengage.add_mobile(`254${formValues.mobileNumber.slice(-9)}`);
  moengage.add_user_attribute("Residence", bookingdetails.result.from);

  this.mixpanelService.track('StartPayment', mixPanelData);
}


mergeInsuranceData(data: any): any[] {
  let mergedData:any = [];
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      const itemsWithKey = data[key].map(item => ({ ...item, key }));
      mergedData = [...mergedData, ...itemsWithKey];
    }
  }
  return mergedData;
}

hideFeedback(){
  this.hideFeedbackBtn=true; 
}

}
